exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-post-parent-markdown-remark-fields-relative-slug-tsx": () => import("./../../../src/pages/blog/{Post.parent__(MarkdownRemark)__fields__relativeSlug}.tsx" /* webpackChunkName: "component---src-pages-blog-post-parent-markdown-remark-fields-relative-slug-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-demo-persona-parent-markdown-remark-fields-relative-slug-tsx": () => import("./../../../src/pages/demo/{Persona.parent__(MarkdownRemark)__fields__relativeSlug}.tsx" /* webpackChunkName: "component---src-pages-demo-persona-parent-markdown-remark-fields-relative-slug-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-funkcje-function-parent-markdown-remark-fields-relative-slug-tsx": () => import("./../../../src/pages/funkcje/{Function.parent__(MarkdownRemark)__fields__relativeSlug}.tsx" /* webpackChunkName: "component---src-pages-funkcje-function-parent-markdown-remark-fields-relative-slug-tsx" */),
  "component---src-pages-funkcje-tsx": () => import("./../../../src/pages/funkcje.tsx" /* webpackChunkName: "component---src-pages-funkcje-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-other-markdown-remark-parent-markdown-remark-fields-relative-slug-tsx": () => import("./../../../src/pages/{OtherMarkdownRemark.parent__(MarkdownRemark)__fields__relativeSlug}.tsx" /* webpackChunkName: "component---src-pages-other-markdown-remark-parent-markdown-remark-fields-relative-slug-tsx" */),
  "component---src-pages-other-mdx-parent-mdx-fields-relative-slug-tsx": () => import("./../../../src/pages/{OtherMdx.parent__(Mdx)__fields__relativeSlug}.tsx" /* webpackChunkName: "component---src-pages-other-mdx-parent-mdx-fields-relative-slug-tsx" */),
  "component---src-pages-szkolenia-uzytkowe-tsx": () => import("./../../../src/pages/szkolenia-uzytkowe.tsx" /* webpackChunkName: "component---src-pages-szkolenia-uzytkowe-tsx" */),
  "component---src-pages-szkolenia-z-rozliczen-nfz-tsx": () => import("./../../../src/pages/szkolenia-z-rozliczen-nfz.tsx" /* webpackChunkName: "component---src-pages-szkolenia-z-rozliczen-nfz-tsx" */),
  "component---src-pages-wsparcie-tsx": () => import("./../../../src/pages/wsparcie.tsx" /* webpackChunkName: "component---src-pages-wsparcie-tsx" */),
  "component---src-pages-zostan-partnerem-it-tsx": () => import("./../../../src/pages/zostan-partnerem-it.tsx" /* webpackChunkName: "component---src-pages-zostan-partnerem-it-tsx" */)
}

